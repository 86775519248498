.dataimg-close-btn {background-image: url("https://img2.cncenter.cz/images/default/gallery/close-btn.svg"); background-repeat: no-repeat;}

.dataimg-enlarge-btn {background-image: url("https://img2.cncenter.cz/images/default/gallery/enlarge-btn.svg"); background-repeat: no-repeat;}

.dataimg-fb-btn {background-image: url("https://img2.cncenter.cz/images/default/gallery/fb-btn.svg"); background-repeat: no-repeat;}

.dataimg-ig-btn {background-image: url("https://img2.cncenter.cz/images/default/gallery/ig-btn.svg"); background-repeat: no-repeat;}

.dataimg-info-btn {background-image: url("https://img2.cncenter.cz/images/default/gallery/info-btn.svg"); background-repeat: no-repeat;}

.dataimg-ln-btn {background-image: url("https://img2.cncenter.cz/images/default/gallery/ln-btn.svg"); background-repeat: no-repeat;}

.dataimg-mail-btn {background-image: url("https://img2.cncenter.cz/images/default/gallery/mail-btn.svg"); background-repeat: no-repeat;}

.dataimg-minify-btn {background-image: url("https://img2.cncenter.cz/images/default/gallery/minify-btn.svg"); background-repeat: no-repeat;}

.dataimg-pi-btn {background-image: url("https://img2.cncenter.cz/images/default/gallery/pi-btn.svg"); background-repeat: no-repeat;}

.dataimg-reload-btn {background-image: url("https://img2.cncenter.cz/images/default/gallery/reload-btn.svg"); background-repeat: no-repeat;}

.dataimg-sharer-btn {background-image: url("https://img2.cncenter.cz/images/default/gallery/sharer-btn.svg"); background-repeat: no-repeat;}

.dataimg-spin-loader {background-image: url("https://img2.cncenter.cz/images/default/gallery/spin-loader.svg"); background-repeat: no-repeat;}

.dataimg-tw-btn {background-image: url("https://img2.cncenter.cz/images/default/gallery/tw-btn.svg"); background-repeat: no-repeat;}

.dataimg-wa-btn {background-image: url("https://img2.cncenter.cz/images/default/gallery/wa-btn.svg"); background-repeat: no-repeat;}

.dataimg-calculator {background-image: url("https://img2.cncenter.cz/images/default/exchange-courses/calculator.svg"); background-repeat: no-repeat;}

.dataimg-eu_flag {background-image: url("https://img2.cncenter.cz/images/default/exchange-courses/eu_flag.svg"); background-repeat: no-repeat;}

.dataimg-gbp_flag {background-image: url("https://img2.cncenter.cz/images/default/exchange-courses/gbp_flag.svg"); background-repeat: no-repeat;}

.dataimg-triangle-down {background-image: url("https://img2.cncenter.cz/images/default/exchange-courses/triangle-down.svg"); background-repeat: no-repeat;}

.dataimg-triangle-right {background-image: url("https://img2.cncenter.cz/images/default/exchange-courses/triangle-right.svg"); background-repeat: no-repeat;}

.dataimg-triangle-up {background-image: url("https://img2.cncenter.cz/images/default/exchange-courses/triangle-up.svg"); background-repeat: no-repeat;}

.dataimg-usa_flag {background-image: url("https://img2.cncenter.cz/images/default/exchange-courses/usa_flag.svg"); background-repeat: no-repeat;}